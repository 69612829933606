<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-3">
        <v-menu
          v-model="dateCurrentFromPick"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateCurrentFrom"
              label="Date From (Current)"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              suffix="*"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateCurrentFromProto"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-3">
        <v-menu
          v-model="dateCurrentToPick"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateCurrentTo"
              label="Date To (Current)"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              suffix="*"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateCurrentToProto"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-3">
        <v-menu
          v-model="datePastFromPick"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datePastFrom"
              label="Date From (Past)"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              suffix="*"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datePastFromProto"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-3">
        <v-menu
          v-model="datePastToPick"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datePastTo"
              label="Date To (Past)"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              suffix="*"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datePastToProto"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="no-print" v-if="dateCurrentFrom && dateCurrentTo">
      <v-col>
        <v-btn @click="buildReport">
          Build Report
        </v-btn>
        &nbsp;
        <v-btn v-if="current" @click="print">
          Print Report
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="current">
      <v-col class="col-6">
        <h4 class="text-center">Current Period</h4>
      </v-col>
      <v-col class="col-6">
        <h4 class="text-center">Previous Period</h4>
      </v-col>
    </v-row>
    <v-row v-if="current">
      <v-col class="col-6">
        <div class="bordered pa-2">
          <v-row>
            <v-col class="col-6">&nbsp;</v-col>
            <v-col class="col-3">Number</v-col>
            <v-col class="col-3">Value</v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <label>Total number of quotations</label>
            </v-col>
            <v-col class="col-3">{{ current.totalQuoted }}</v-col>
            <v-col class="col-3">{{ current.totalQuotedValue | priceInPounds | currency }}</v-col>
          </v-row>
        </div>
      </v-col>
      <v-col class="col-6">
        <div class="bordered pa-2">
          <v-row>
            <v-col class="col-6">&nbsp;</v-col>
            <v-col class="col-3">Number</v-col>
            <v-col class="col-3">Value</v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <label>Total number of quotations</label>
            </v-col>
            <v-col class="col-3">{{ past.totalQuoted }}</v-col>
            <v-col class="col-3">{{ past.totalQuotedValue | priceInPounds | currency }}</v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="current" class="mt-4">
      <v-col class="col-6">
        <div class="bordered pa-2">
          <v-row>
            <v-col class="col-6">&nbsp;</v-col>
            <v-col class="col-3">Number</v-col>
            <v-col class="col-3">Value</v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <label>Total converted</label>
            </v-col>
            <v-col class="col-3">{{ current.totalConverted }}</v-col>
            <v-col class="col-3">{{ current.totalConvertedValue | priceInPounds | currency }}</v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <label>Invoiced</label>
            </v-col>
            <v-col class="col-3">{{ current.totalInvoiced }}</v-col>
            <v-col class="col-3">{{ current.totalInvoicedValue | priceInPounds | currency }}</v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <label>Credits</label>
            </v-col>
            <v-col class="col-3">{{ current.totalCredits }}</v-col>
            <v-col class="col-3">{{ current.totalCreditsValue | priceInPounds | currency }}</v-col>
          </v-row>
        </div>
      </v-col>
      <v-col class="col-6">
        <div class="bordered pa-2">
          <v-row>
            <v-col class="col-6">&nbsp;</v-col>
            <v-col class="col-3">Number</v-col>
            <v-col class="col-3">Value</v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <label>Total converted</label>
            </v-col>
            <v-col class="col-3">{{ past.totalConverted }}</v-col>
            <v-col class="col-3">{{ past.totalConvertedValue | priceInPounds | currency }}</v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <label>Invoiced</label>
            </v-col>
            <v-col class="col-3">{{ past.totalInvoiced }}</v-col>
            <v-col class="col-3">{{ past.totalInvoicedValue | priceInPounds | currency }}</v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <label>Credits</label>
            </v-col>
            <v-col class="col-3">{{ past.totalCredits }}</v-col>
            <v-col class="col-3">{{ past.totalCreditsValue | priceInPounds | currency }}</v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="current" class="mt-4">
      <v-col class="col-6">
        <div class="bordered pa-2">
          <v-row>
            <v-col class="col-6">&nbsp;</v-col>
            <v-col class="col-3">Number</v-col>
            <v-col class="col-3">Value</v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <label>Total Lost</label>
            </v-col>
            <v-col class="col-3">{{ current.totalLost }}</v-col>
            <v-col class="col-3">{{ current.totalLostValue | priceInPounds | currency }}</v-col>
          </v-row>
        </div>
      </v-col>
      <v-col class="col-6">
        <div class="bordered pa-2">
          <v-row>
            <v-col class="col-6">&nbsp;</v-col>
            <v-col class="col-3">Number</v-col>
            <v-col class="col-3">Value</v-col>
          </v-row>
          <v-row>
            <v-col class="col-6">
              <label>Total Lost</label>
            </v-col>
            <v-col class="col-3">{{ past.totalLost }}</v-col>
            <v-col class="col-3">{{ past.totalLostValue | priceInPounds | currency }}</v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row class="no-print" v-if="!loadBreakdown && current">
      <v-col>
        <v-btn @click="loadTheBreakdown">Load breakdown by staff</v-btn>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="loadBreakdown && !staffCurrent"
      class="mx-auto mt-4"
      type="card">
    </v-skeleton-loader>
    <div style="break-before: always;">&nbsp;</div>
    <v-row v-if="loadBreakdown && staffCurrent">
      <v-col>
        <h4 class="text-center">Quotations by Staff</h4>
      </v-col>
    </v-row>
    <v-row v-if="loadBreakdown && staffCurrent">
      <v-col class="col-6">
        <h4>Curent Period</h4>
      </v-col>
      <v-col class="col-6">
        <h4>Previous Period</h4>
      </v-col>
    </v-row>
    <v-row v-if="loadBreakdown && staffCurrent">
      <v-col class="col-6">
        <v-simple-table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Quoted</th>
              <th>Value</th>
              <th>Converted</th>
              <th>Value</th>
              <th>Lost</th>
              <th>Value</th>
              <th>Invoiced</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in staffCurrent" :key="`c_${index}`">
              <td>{{ user.name }}</td>
              <td>{{ user.totalQuoted }}</td>
              <td>{{ user.totalQuotedValue | priceInPounds | currency }}</td>
              <td>{{ user.totalConverted }}</td>
              <td>{{ user.totalConvertedValue | priceInPounds | currency }}</td>
              <td>{{ user.totalLost }}</td>
              <td>{{ user.totalLostValue | priceInPounds | currency }}</td>
              <td>{{ user.totalInvoiced }}</td>
              <td>{{ user.totalInvoicedValue | priceInPounds | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col class="col-6">
        <v-simple-table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Quoted</th>
              <th>Value</th>
              <th>Converted</th>
              <th>Value</th>
              <th>Lost</th>
              <th>Value</th>
              <th>Invoiced</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in staffPast" :key="`c_${index}`">
              <td>{{ user.name }}</td>
              <td>{{ user.totalQuoted }}</td>
              <td>{{ user.totalQuotedValue | priceInPounds | currency }}</td>
              <td>{{ user.totalConverted }}</td>
              <td>{{ user.totalConvertedValue | priceInPounds | currency }}</td>
              <td>{{ user.totalLost }}</td>
              <td>{{ user.totalLostValue | priceInPounds | currency }}</td>
              <td>{{ user.totalInvoiced }}</td>
              <td>{{ user.totalInvoicedValue | priceInPounds | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { format } from 'date-fns';
import axios from '../../axios';

export default {
  name: 'SalesStatisticsSummary',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      dateCurrentFrom: null,
      dateCurrentFromProto: null,
      dateCurrentFromPick: false,
      dateCurrentTo: null,
      dateCurrentToProto: null,
      dateCurrentToPick: false,
      datePastFrom: null,
      datePastFromProto: null,
      datePastFromPick: false,
      datePastTo: null,
      datePastToProto: null,
      datePastToPick: false,
      current: null,
      past: null,
      staffCurrent: null,
      staffPast: null,
      loadBreakdown: false,
    };
  },
  watch: {
    dateCurrentFromProto () {
      this.dateCurrentFrom = this.formatDate(this.dateCurrentFromProto);
      this.datePastFrom = this.formatDate(this.subtractYear(this.dateCurrentFromProto));
    },
    dateCurrentToProto () {
      this.dateCurrentTo = this.formatDate(this.dateCurrentToProto);
      this.datePastTo = this.formatDate(this.subtractYear(this.dateCurrentToProto));
    },
    datePastFromProto () {
      this.datePastFrom = this.formatDate(this.datePastFromProto);
    },
    datePastToProto () {
      this.datePastTo = this.formatDate(this.datePastToProto);
    },
  },
  methods: {
    print() {
      window.print();
    },
    buildReport() {
      this.current = null;
      this.past = null;
      this.staffCurrent = null;
      this.staffPast = null;
      const postData = {};
      postData.dateCurrentFrom = this.dateCurrentFrom;
      postData.dateCurrentTo = this.dateCurrentTo;
      postData.datePastFrom = this.datePastFrom;
      postData.datePastTo = this.datePastTo;
      axios.post(`/quotations/salesStatisticsSummary.json?token=${this.token}`, postData)
        .then((response) => {
          this.current = response.data.current;
          this.past = response.data.past;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadTheBreakdown() {
      this.loadBreakdown = true;
      const postData = {};
      postData.dateCurrentFrom = this.dateCurrentFrom;
      postData.dateCurrentTo = this.dateCurrentTo;
      postData.datePastFrom = this.datePastFrom;
      postData.datePastTo = this.datePastTo;
      axios.post(`/quotations/salesStatisticsSummaryByUser.json?token=${this.token}`, postData)
        .then((response) => {
          this.staffCurrent = response.data.current;
          this.staffPast = response.data.past;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    subtractYear(dt) {
      const date = new Date(dt);
      const year = date.getFullYear();
      const yearAgo = (year - 1);
      const dateAgo = date.setFullYear(yearAgo);
      return format(new Date(dateAgo), 'yyy-MM-dd');
    },
  },
};
</script>